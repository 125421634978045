<template>
  <div class="mt-3 mt-md-0">
    <div class="intro-banner text-white text-xl">
      <p>溫馨小提醒</p>
    </div>
    <!-- 書信 -->
    <div class="intro-text bg-white p-4 text-lg" v-if="$route.name === 'ai_assistantMain' && aiType === 'letter'">
      <h6 class="mb-1 font-bold text-lg">目前總點數：<span>{{ Number(remainPoints) }}</span> 點
        <a @click="swal_remainPoints" class="pointer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg></a>
      </h6>
      <h6 class="font-bold text-lg">商用英文書信每次扣除：<span>20</span> 點</h6>
      <ul class="generate_ul">
        <li><p><span class="title">步驟說明：</span>請先填寫寄件人與收件人資訊，再輸入信件資訊。</p></li>
        <li><p><span class="title">定義內容：</span>AI 小幫手生成後，您可依據實際使用需求，進一步針對細部書信內容調整。</p></li>
        <li><p><span class="title">重視隱私與安全：</span>在輸入資訊時，請謹慎不要透露自己或他人的個資或私人資訊。</p></li>
        <li><p><span class="title">再次核對：</span>本篇商用英文書信為 AI 小幫手自動生成，建議使用前再次斟酌核對相關細節。</p></li>
      </ul>
    </div>

    <!-- 演講稿 -->
    <div class="intro-text bg-white p-4 text-lg" v-if="$route.name === 'ai_assistantMain' && aiType === 'speech'">
      <h6 @click="swal_remainPoints" class="mb-1 font-bold text-lg">目前總點數：<span>{{ Number(remainPoints) }}</span> 點
        <a @click="swal_remainPoints" class="pointer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg></a>
      </h6>
      <h6 class="font-bold text-lg">英文演講稿每次扣除：<span>20</span> 點</h6>
      <ul class="generate_ul">
        <li><p><span class="title">步驟說明：</span>請先填寫主題與對象資訊，再輸入內容簡述。</p></li>
        <li><p><span class="title">定義內容：</span>AI 小幫手生成後，您可依據實際使用需求，進一步針對細部講稿內容調整。</p></li>
        <li><p><span class="title">重視隱私與安全：</span>在輸入資訊時，請謹慎不要透露自己或他人的個資或私人資訊。</p></li>
        <li><p><span class="title">再次核對：</span>本篇英文演講稿為 AI 小幫手自動生成，建議使用前再次斟酌核對相關細節。</p></li>
      </ul>
    </div>

    <!-- 口說 -->
    <div class="intro-text bg-white p-4 text-lg" v-if="$route.name === 'ChatAI'">
      <h6 @click="swal_remainPoints" class="mb-1 font-bold text-lg">目前總點數：<span>{{ Number(remainPoints) }}</span> 點
        <a @click="swal_remainPoints" class="pointer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg></a>
      </h6>
      <h6 class="font-bold text-lg mb-3">口說練習每次扣除：<span>1</span> 點</h6>
      <h6 class="font-bold text-lg">操作說明：</h6>
      <ul class="chat_ul">
        <li><p class="mb-2">點<img src="@/assets/image/ai/mic_C.png" alt="" class="d-inline-block icon-img m-1">說出回應的句子，再點<img src="@/assets/image/ai/submit_icon.png" alt="" class="d-inline-block icon-img-sent m-1">，送出後隨即扣點。</p></li>
        <li><p class="mb-2">點<img src="@/assets/image/ai/AI_A.png" alt="" class="d-inline-block icon-img m-1">參考 AI 回應內容，並練習說說看。</p></li>
        <li><p class="mb-2">點<img src="@/assets/image/ai/btn_AItalk_voice.png" alt="" class="d-inline-block icon-img m-1">聆聽自己的錄音。</p></li>
        <li><p class="mb-2">點<img src="@/assets/image/ai/btn_AItalk_correct.png" class="d-inline-block icon-img m-1">觀看 AI 反饋建議，讓自己下次說得更好。</p></li>
        <li><p class="mb-2">若 AI 回應不符預期，請修改文字內容或點擊重新整理。</p></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Rule',
  props: {
    aiType: {
      type: String,
      default: 'letter'
    }
  },
  computed: {
    ...mapGetters([
      'remainPoints'
    ])
  },
  methods: {
    swal_remainPoints () {
      let self = this
      self.$swal({
        html: `<h6>目前總點數：<span class="text-red-main-ai">${self.remainPoints}</span> 點</h6><p>本區依企業方案提供初始點數供您體驗 AI 服務，每次使用以下服務將自動扣除點數。</p><ul><li>商用英文書信：每次 20 點</li><li>英文演講稿：每次 20 點</li><li>口說練習：每次 1 點</li></ul>`,
        showCloseButton: false,
        focusConfirm: false,
        confirmButtonText: `我知道了`,
        title: '使用規範',
        customClass: {
          title: 'text-white',
          container: '',
          popup: 'p-0',
          header: 'swal-header-notice justify-content-center align-items-center',
          closeButton: '',
          htmlContainer: 'remainPoints_swal my-3',
          actions: 'mb-4 mt-2',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          self.postData()
        }
      }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
  .intro-banner {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: #0E103E;
    text-align: center;
    font-weight: 700;
    background-image:
      url('~@/assets/image/ai/plusSS.png'), url('~@/assets/image/ai/plusSS.png'),
      url('~@/assets/image/ai/plusS.png'), url('~@/assets/image/ai/plusS.png'),
      url('~@/assets/image/ai/plusB.png'), url('~@/assets/image/ai/plusB.png'),
      url('~@/assets/image/ai/robotL.png'), url('~@/assets/image/ai/robotR.png');
    background-repeat: no-repeat;
    background-position:
      left 18% top 15%, right 18% top 15%,
      left 6% top 30%, right 6% top 30%,
      left 13% top 55%, right 13% top 55%,
      left 10% bottom 0%, right 5% bottom 0%;
    p {
      margin: 0;
    }
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
  .intro-text{
    svg {
      width: 1em;
    }
    h6 {
      span {
        color: #F30000;
      }
      @media (max-width: 1024px) {
        font-size: 1rem;
      }
    }
    p {
      margin-bottom: 1.25rem;
      line-height: 150%;
      font-weight: 500;
      @media (max-width: 1024px) {
        font-size: 1rem;
      }
    }
    .title {
      font-weight: 700;
    }
    .icon-img {
      max-width: 1.5em;
      height: auto;
      object-fit: contain;
    }
    .icon-img-sent {
      max-width: 1.3em;
      height: auto;
      object-fit: contain;
    }
  }
  .generate_ul {
    list-style-type: none;
    list-style-position: outside;
    padding-left: 0;
  }
  .chat_ul {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 1.1em;
  }
</style>
